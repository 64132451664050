import React from 'react';
import PropTypes from 'prop-types';
import mergeClassNames from 'classnames';
import { config } from '@fortawesome/fontawesome-svg-core';
import * as BulmaCSS from './bulma.module.css';
import Profile from './Components/Profile';
import * as Styles from './styles.module.css';
import Section from './Components/Section';
import ExperiencesList from './Components/ExperiencesList';
import ProjectsList from './Components/ProjectsList';
import TagsList from './Components/TagsList';
import CommonList from './Components/CommonList';
import logo from '../public/img/ivan-ros-navarro.jpg';

import '@fortawesome/fontawesome-svg-core/styles.css';

config.autoAddCss = false;

const componnentMap = {
  'experiences-list': ExperiencesList,
  text: Section,
  'projects-list': ProjectsList,
  'tag-list': TagsList,
  'common-list': CommonList,
};

export default function ReactCV({ personalData, sections, branding }) {
  return (
    <section className={mergeClassNames(Styles.appContainer, BulmaCSS.section)}>
      <main className={BulmaCSS.container}>
        <div className={mergeClassNames(Styles.cvContainer, BulmaCSS.box)}>
          <Profile
            {...personalData}
          />
          {
        sections.map((sectionDetails, i) => {
          const { type } = sectionDetails;
          const Comp = componnentMap[type] || Section; // Fallback to section for any case.
          return <Comp {...sectionDetails} key={i} />;
        })
      }

        </div>
      </main>
      {branding && (
      <div className={Styles.branding}>
        Creado con React por <a href="https://ivanros.com" rel="noreferrer">Iván Ros Navarro</a>
      </div>
      )}
    </section>
  );
}

ReactCV.propTypes = {
  personalData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.oneOf(['email', 'website', 'phone', 'location', 'linkedin', 'github', 'twitter']),
      value: PropTypes.string,
    })),
    languages: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      level: PropTypes.string,
    })),
    interests: PropTypes.arrayOf(PropTypes.string),
  }),
  sections: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
  })),
  branding: PropTypes.bool,
};

ReactCV.defaultProps = {
  personalData: {
    name: 'Iván Ros Navarro',
    title: 'Desarrollador Full Stack',
    image: logo,
    contacts: [
      { type: 'email', value: 'ivan@ivanros.com' },
      { type: 'phone', value: '+34 676 104 394' },
      { type: 'location', value: 'Murcia' },
      { type: 'website', value: 'ivanros.com' },
      { type: 'linkedin', value: 'linkedin.com/in/ivanrosnavarro/' },
      { type: 'twitter', value: 'twitter.com/IvanRosWeb' },
      { type: 'github', value: 'github.com/IvanRosNavarro' }
    ]
  },  
  sections: [
    {
      type: 'text',
      title: 'Sobre mí',
      description: 'Optional',
      content: `**En constante aprendizaje** \n\n #### Soy una persona resolutiva, amable, con amplias dotes de trabajo en equipo, con iniciativa y ganas de seguir aprendiendo. \n\n &nbsp; \n\n Me gusta el cine, la literatura, la música y el
      deporte. En mis ratos libres también hago webs, toco el piano y escribo relatos.`,
      icon: 'usertie'
    }],
  branding: true,
};
